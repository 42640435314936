import React, { useEffect, useState } from 'react';
import "./AiServices.css";
import aiMan from "../../assets/aiMan.png";
import AIAccordion from "./AIAccordion";
import robotic from "../../assets/robotic.png";
import intellegance from "../../assets/intellegence.png";
import aiBrain from "../../assets/aiBrain.png";
import aiForm from "../../assets/aiForm.png";
import aiSetting from "../../assets/aiSetting.png";
import aiManIcon from "../../assets/aiManIcon.png";
import aiPlugs from "../../assets/aiPlugs.png";
import platforms from "../../assets/platforms.png";
import humanAI from "../../assets/humanAI.png";
import world from "../../assets/world.png";
import { Footer } from "../../container/footerSection/Footer";


const services = [
  { id: 1, title: "Social Media Services", image: robotic },
  { id: 2, title: "AI Chatbots", image: intellegance },
  { id: 3, title: "Predictive Analytics", image: aiBrain },
  { id: 4, title: "Custom AI Solutions", image: aiSetting },
  { id: 5, title: "Machine Models", image: aiManIcon },
  { id: 6, title: "Data Processing", image: aiPlugs },
  { id: 7, title: "Image Recognition", image: robotic },
  { id: 8, title: "Voice Assistants", image: aiBrain },
];

const AiServices = () => {

  const [branches, setBranches] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetch(
          'https://test-project-hm46.onrender.com/api/v1/tc_block_generate_source_code/fetch_branches',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH"
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setBranches(data);
      } catch (error) {
        console.log(error, 'error')
        setError(error.message);
      }
    };

    fetchBranches();
  }, []);





  return (
    <>
      <div className="container">
        <div className="row ai-dashboard">
          <div className="col-lg-7 col-md-6 col-sm-12">
            <div className="ai-content-dashboard">
              <h5>AI SERVICES</h5>
              <h2>We create the most realistic artificial intelligence</h2>
              <p>
                At TaskCraft, we specialize in building advanced AI-powered
                solutions that drive innovation and efficiency for businesses of
                all sizes. Our expertise lies in creating intelligent systems
                that understand, learn, and evolve to meet your unique needs.
                Whether it’s AI-driven automation, predictive analytics, or
                natural language processing, we deliver solutions that empower
                your business to stay ahead in a competitive market.
              </p>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <ul className="p-0 ai-service-list">
                    <li>
                      {" "}
                      <i class="fa-regular fa-circle-check"></i> Advanced
                      AI-Powered Solutions
                    </li>
                    <li>
                      {" "}
                      <i class="fa-regular fa-circle-check"></i>Customizable to
                      Business Needs
                    </li>
                    <li>
                      {" "}
                      <i class="fa-regular fa-circle-check"></i>Enhanced
                      Efficiency and Automation
                    </li>
                    <li>
                      {" "}
                      <i class="fa-regular fa-circle-check"></i>Future-Ready
                      Intelligent Systems
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <ul className="p-0 ai-service-list">
                    <li>
                      {" "}
                      <i class="fa-regular fa-circle-check"></i>Seamless
                      Integration Capabilities
                    </li>
                    <li>
                      {" "}
                      <i class="fa-regular fa-circle-check"></i>Real-Time
                      Insights and Analytics
                    </li>
                    <li>
                      {" "}
                      <i class="fa-regular fa-circle-check"></i>Scalable for
                      Growth and Expansion
                    </li>
                    <li>
                      {" "}
                      <i class="fa-regular fa-circle-check"></i>Reliable and
                      Secure Implementations
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12">
            <img src={aiMan} alt="ai" className="ai-image-dashboard" />
          </div>
        </div>
        <hr />

        <div className="ai-form-section">
          <h1>Start your AI journey today</h1>
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="ai-service-form">
                <p>To get AI Services</p>
                <form className="ai-form-container">
                  <input
                    type="text"
                    placeholder="Project Name"
                    className="ai-form-input"
                  />

                  <select
                    class="form-select ai-form-input"
                    aria-label="Default select example"
                  >
                    {branches?.branches?.map((item, index) => (
                      <option style={{height:"50px"}} key={index} value={index + 1}>{item}</option>
                    ))}

                  </select>
                  <button className="submit-btn">Sumbit Request</button>
                </form>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12">
              <AIAccordion />
            </div>
          </div>
        </div>

        <div className="row ai-service-cards">
          {services.map((service) => (
            <div
              className="col-lg-3 col-md-4 col-sm-6 col-xs-6"
              key={service.id}
            >
              <div className="service-card">
                <img src={service.image} alt="ai-img" />
                <h6>{service.title}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="reach-more-audience">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-4 reach-audience-content">
              <img src={platforms} alt="platforms" />
              <h5>On Any Platform</h5>
              <p>
                Capitalize on low hanging fruit to identify a ballpark value
                added activity to beta test. Override the digital divide wit
                additional clickthroughs
              </p>
            </div>
            <div className="col-lg-4 col-md-4  reach-audience-content">
              <img src={humanAI} alt="platforms" />
              <h5>Across Devices</h5>
              <p>
                Capitalize on low hanging fruit to identify a ballpark value
                added activity to beta test. Override the digital divide wit
                additional clickthroughs
              </p>
            </div>
            <div className="col-lg-4 col-md-4 reach-audience-content">
              <img src={world} alt="world" />
              <h5>Around the World</h5>
              <p>
                Capitalize on low hanging fruit to identify a ballpark value
                added activity to beta test. Override the digital divide wit
                additional clickthroughs
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="why-choose-us">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img src={aiForm} alt="why-choose" className="why-choose-img" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="why-choose-content">
                <h5>WHY CHOOSSE US</h5>
                <h2>We are provide best product features for you</h2>
                <p>
                  At TaskCraft, we redefine the way you build digital solutions
                  by blending cutting-edge AI with industry expertise. Our
                  streamlined processes ensure faster delivery without
                  compromising on quality. We prioritize innovation, crafting
                  tailored web and app solutions that align perfectly with your
                  business goals. With a customer-centric approach, we focus on
                  delivering exceptional user experiences that leave a lasting
                  impact. Our team of skilled professionals is committed to
                  providing scalable, reliable, and future-ready solutions.
                  TaskCraft's AI-driven insights enhance performance and
                  efficiency, giving you a competitive edge. We believe in
                  transparency, ensuring you’re informed at every stage of the
                  project.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AiServices;
