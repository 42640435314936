import React from "react";
import SD from "../../../assets/images/software-engineer.png";
import ES from "../../../assets/images/outsourcing.png";
import IM from "../../../assets/images/data-management.png";
import AI from "../../../assets/images/AI_logo.png";
import ec from "../../../assets/images/e-commerce_logo.png";
import AS from "../../../assets/images/Application-services.png";
import { Button } from "../../../components/button";
import "./ServiceSectionStyle.css";
import { Link } from "react-router-dom";
import { routes } from "../../../routes/routes";

export default function ServiceSection() {
  return (
    <>
      <section
        id="ourservice"
        className="service-Section-Header"
        data-aos="fade-up"
        data-aos-duration="4000"
      >
        <h4 className="text-Services">Our Services</h4>
        <div className="service-Divider">
          <div className="service-Divider-Separator"> </div>
        </div>
        <p className="sub-text-services">
          Our innovative services integrate seamlessly to modernize workflows,
          accelerate digital transformation,
          <br /> and deliver scalable, measurable outcomes
        </p>

        <div className="services-main-card-section">
          <div className="content-second-column">
            <div className="row second-column-first-rows ">
              {/* AI/ML section adding 222 new*/}
              <div className="col columns-of-second-section my-5">
                <Link
                  to={routes.PrdouctsRoutes.AIMl}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  {" "}
                  <div
                    className="col container-second-column"
                    style={{
                      backgroundImage: `url("https://www.silvertouchtech.co.uk/wp-content/uploads/2020/05/ai-banner-1568x625.jpg")`,
                      backgroundSize: "cover",
                      backgroundPosition: "0px -136px",
                      color: "white",
                    }}
                  >
                    <div className="service-section-tabs-icon">
                      <img src={AI} alt="AI and Machine Learning Solutions" />
                    </div>
                    <div
                      className="col service-section-tabs-content"
                      style={{ color: "white" }}
                    >
                      <h5 style={{ color: "white" }}>AI & ML Solutions</h5>
                      <Link
                        to={routes.PrdouctsRoutes.AIMl}
                        style={{ color: "white" }}
                      >
                        <span>AI ML Consulting</span>
                      </Link>
                      <Link
                        to={routes.hireDevelopersRoutes.MLModelDevelopment}
                        style={{ color: "white" }}
                      >
                        <span>ML Model Development</span>
                      </Link>
                      <Link
                        to={routes.hireDevelopersRoutes.NLPServices}
                        style={{ color: "white" }}
                      >
                        <span>NLP Services</span>
                      </Link>
                      <Link
                        to={routes.hireDevelopersRoutes.AIIntegration}
                        style={{ color: "white" }}
                      >
                        <span>AI Integration</span>
                      </Link>
                    </div>
                  </div>{" "}
                </Link>
                {/* E-commerce section */}
                <Link
                  to={routes.PrdouctsRoutes.Ecommerce}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  {" "}
                  <div
                    className="col container-second-column"
                    id="ec_bg"
                    style={{
                      backgroundImage: `url("https://img.freepik.com/premium-photo/shopping-cart-card-icon-discounts_116441-26066.jpg")`,
                      backgroundSize: "cover",
                      color: "white",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="service-section-tabs-icon">
                      <img src={ec} alt="E-commerce Services" />
                    </div>
                    <div
                      className="col service-section-tabs-content"
                      style={{ color: "white" }}
                    >
                      <h5 style={{ color: "white" }}>E-commerce Services</h5>
                      <Link
                        to={routes.PrdouctsRoutes.Ecommerce}
                        style={{ color: "white" }}
                      >
                        <span>E-commerce Consulting</span>
                      </Link>
                      <Link
                        to={routes.hireDevelopersRoutes.StoreSetup}
                        style={{ color: "white" }}
                      >
                        <span>Store Setup & Management</span>
                      </Link>
                      <Link
                        to={routes.hireDevelopersRoutes.PaymentIntegration}
                        style={{ color: "white" }}
                      >
                        <span>Payment Gateway Integration</span>
                      </Link>
                      <Link
                        to={routes.hireDevelopersRoutes.EcommerceDevelopment}
                        style={{ color: "white" }}
                      >
                        <span>E-commerce Development</span>
                      </Link>
                    </div>
                  </div>{" "}
                </Link>
                {/* No code low code */}
                <Link
                  to={routes.PrdouctsRoutes.NoCodeLowCode}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  {" "}
                  <div
                    className="col container-second-column"
                    style={{
                      backgroundImage: `url("https://www.jadeglobal.com/sites/default/files/2022-09/Value-Generation-Using-Low-Code-No-Code-Platforms.jpg")`,
                      backgroundSize: "cover",
                      backgroundPosition: "0px -136px",
                      color: "white",
                    }}
                  >
                    <div className="service-section-tabs-icon">
                      <img
                        src={
                          "https://cdn-icons-png.flaticon.com/512/13092/13092250.png"
                        }
                        alt="No Code Low Code Solutions"
                      />{" "}
                      {/* Replace `NoCodeLowCodeIcon` with the appropriate icon for No Code Low Code */}
                    </div>
                    <div
                      className="col service-section-tabs-content"
                      style={{ color: "white" }}
                    >
                      <h5 style={{ color: "white" }}>
                        No Code Low Code Solutions
                      </h5>
                      <Link
                        to={routes.PrdouctsRoutes.NoCodeLowCode}
                        style={{ color: "white" }}
                      >
                        <span>No Code Consulting</span>
                      </Link>
                      <Link
                        to={
                          routes.hireDevelopersRoutes.LowCodePlatformDevelopment
                        }
                        style={{ color: "white" }}
                      >
                        <span>Low Code Platform Development</span>
                      </Link>
                      <Link
                        to={routes.hireDevelopersRoutes.AppAutomation}
                        style={{ color: "white" }}
                      >
                        <span>App Automation</span>
                      </Link>
                      <Link
                        to={routes.hireDevelopersRoutes.NCIntegration}
                        style={{ color: "white" }}
                      >
                        <span>No Code Integration</span>
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                <br></br>
              </div>
              <div className="col-4 whole-first-column">
                <div className="container-first-column">
                  <div className="container-first-column-gradient">
                    <div className="container-first-column-content">
                      <b>
                        <h3>Our Services</h3>
                      </b>
                      <p className="services-section-content">
                        Redefining customer satisfaction with innovative IT{" "}
                        <br />
                        solutions that deliver seamless integration, optimized{" "}
                        <br />
                        performance,and exceptional support for your <br />{" "}
                        digital needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col columns-of-second-section">
                <div className="col container-second-column">
                  <div className="service-section-tabs-icon_update">
                    <img src={SD} alt="software development"></img>
                  </div>

                  <div className="col service-section-tabs-content">
                    <h5 style={{ marginTop: "-20px", color: "#f67c05" }}>
                      Software Development
                    </h5>
                    <Link
                      to={
                        routes.hireDevelopersRoutes.customApplicationDevelopment
                      }
                    >
                      <span>Custom Application Development</span>
                    </Link>
                    <Link
                      to={routes.hireDevelopersRoutes.customMobileDevelopment}
                    >
                      <span>Custom Mobile Development</span>
                    </Link>
                    <Link to={routes.hireDevelopersRoutes.webDevelopment}>
                      <span>Web Development </span>
                    </Link>
                    <Link to={routes.hireDevelopersRoutes.productEngineering}>
                      <span>Product Engineering </span>
                    </Link>
                  </div>
                </div>
                <div className="col container-second-column">
                  <div className="service-section-tabs-icon_update">
                    <img src={IM} alt="infrastructure management"></img>
                  </div>
                  <div className="col service-section-tabs-content">
                    <h5 style={{ marginTop: "-20px", color: "#f67c05" }}>
                      Infrastructure Management
                    </h5>
                    <Link
                      to={routes.hireDevelopersRoutes.cloudAndInfrastructure}
                    >
                      <span>Cloud & Infrastructure</span>
                    </Link>
                    <Link to={routes.hireDevelopersRoutes.devOpsSolutions}>
                      <span>DevOps Solutions</span>
                    </Link>
                    <Link
                      to={routes.hireDevelopersRoutes.salesforceDevelopment}
                    >
                      <span>Salesforce Development</span>
                    </Link>
                    <Link to={routes.hireDevelopersRoutes.managedAWSService}>
                      <span>Managed AWS Services</span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col columns-of-second-section">
                <div className="col container-second-column">
                  <div className="service-section-tabs-icon_update">
                    <img src={ES} alt="enterprises solution"></img>
                  </div>
                  <div className="col service-section-tabs-content">
                    <h5 style={{ marginTop: "-20px", color: "#f67c05" }}>
                      Enterprise Solutions
                    </h5>
                    <Link to={routes.hireDevelopersRoutes.ERPSolutions}>
                      <span>ERP Solutions</span>
                    </Link>
                    <Link to={routes.hireDevelopersRoutes.onDemandSolutions}>
                      <span>On-demand Solutions</span>
                    </Link>
                    <Link to={routes.hireDevelopersRoutes.mobilitySolutions}>
                      <span>Mobility Solutions</span>
                    </Link>
                    <Link to={routes.hireDevelopersRoutes.staffAugmentation}>
                      <span>Staff Augmentation</span>
                    </Link>
                  </div>
                </div>
                <div className="col container-second-column">
                  <div className="service-section-tabs-icon_update">
                    <img src={AS} alt="Application services"></img>
                  </div>
                  <div className="col service-section-tabs-content">
                    <h5 style={{ marginTop: "-20px", color: "#f67c05" }}>
                      Application Services
                    </h5>
                    <Link to={routes.hireDevelopersRoutes.APIDevelopment}>
                      <span>API Development</span>
                    </Link>
                    <Link to={routes.hireDevelopersRoutes.testingSolutions}>
                      <span>Testing Solutions</span>
                    </Link>
                    <Link to={routes.hireDevelopersRoutes.databaseSolutions}>
                      {" "}
                      <span>Database Solutions</span>
                    </Link>
                    <Link to={routes.hireDevelopersRoutes.backEndSolutions}>
                      <span>Back-End Solutions </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
