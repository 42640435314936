import { useState } from "react";
import "./EventStyle.css";
import { Footer } from "../../container/footerSection/Footer";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const images = [
  {
    id: 1,
    src: "https://cdn.pixabay.com/photo/2016/11/23/15/48/audience-1853662_640.jpg",
    title: "Event 1",
  },
  {
    id: 2,
    src: "https://images.pexels.com/photos/1763067/pexels-photo-1763067.jpeg?cs=srgb&dl=pexels-sebastian-ervi-866902-1763067.jpg&fm=jpg",
    title: "Event 2",
  },
  {
    id: 3,
    src: "https://socio.events/wp-content/uploads/2022/10/AdobeStock_240758729-scaled.jpeg",
    title: "Event 3",
  },
  {
    id: 4,
    src: "https://thumbor-cdn.b2match.com/bCC4xgRJbRZEiT9DUJvb3WJznrk=/full-fit-in/1600x0/filters:quality(75):no_upscale()/https://www.b2match.com/img/events-site_header-image.jpg",
    title: "Event 4",
  },
  {
    id: 5,
    src: "https://images.pexels.com/photos/433452/pexels-photo-433452.jpeg?cs=srgb&dl=pexels-pixabay-433452.jpg&fm=jpg",
    title: "Event 5",
  },
  {
    id: 6,
    src: "https://thumbor-cdn.b2match.com/bCC4xgRJbRZEiT9DUJvb3WJznrk=/full-fit-in/1600x0/filters:quality(75):no_upscale()/https://www.b2match.com/img/events-site_header-image.jpg",
    title: "Event 6",
  },
  {
    id: 7,
    src: "https://cdn.pixabay.com/photo/2017/12/08/11/53/event-party-3005668_1280.jpg",
    title: "Event 7",
  },
  {
    id: 8,
    src: "https://images.pexels.com/photos/1763067/pexels-photo-1763067.jpeg?cs=srgb&dl=pexels-sebastian-ervi-866902-1763067.jpg&fm=jpg",
    title: "Event 8",
  },
  {
    id: 9,
    src: "https://cdn.pixabay.com/photo/2016/11/23/15/48/audience-1853662_640.jpg",
    title: "Event 9",
  },
  {
    id: 10,
    src: "https://socio.events/wp-content/uploads/2022/10/AdobeStock_240758729-scaled.jpeg",
    title: "Event 10",
  },
  {
    id: 11,
    src: "https://cdn.choosechicago.com/uploads/2019/06/festivals-events.jpg",
    title: "Event 11",
  },
  {
    id: 12,
    src: "https://www.cvent.com/sites/default/files/image/2024-01/Header%20Image%20-%20Trends%202024.webp",
    title: "Event 12",
  },
];

export const Events = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <section className="main-content">
        <div className="app-container">
          <section className="gallery-section">
            <h2 className="gallery-title">Events</h2>
            <div className="gallery-grid">
              {images.map((image) => (
                <div
                  key={image.id}
                  className="gallery-item"
                  onClick={() => openModal(image)}
                >
                  <img src={image.src} alt={image.title} />
                  <div className="gallery-item-title">{image.title}</div>
                </div>
              ))}
            </div>
          </section>
          <section className="about-section">
            <h2>Celebrating Milestones Together</h2>
            <p>
              At <b>Taskcraft</b> Technologies, we celebrate milestones and the
              moments that define our culture. From team-building events to
              company celebrations, our gallery captures the essence of growth,
              teamwork, and success.
            </p>
            <p>
              Whether it’s an anniversary, a creative workshop, or a holiday
              celebration, our gallery reflects the energy and enthusiasm that
              drive us forward with passion and innovation.
            </p>
            <p>
              Explore the memories that shape us and join us in celebrating the
              milestones that matter. At Taskcraft Technologies, we nurture
              creativity, collaboration, and celebration in everything we do.
            </p>
          </section>
          <section className="projects-section">
            <h2>Featured Projects</h2>
            <div className="projects-grid">
              <div className="Event-item">Community Art Festival</div>
              <div className="Event-item">Youth Workshop</div>
              <div className="Event-item">Annual Charity Gala</div>
            </div>
          </section>

          <section className="testimonials-section">
            <h2>Testimonials</h2>
            <div className="testimonials-grid">
              <div className="testimonial">
                <p>
                  An amazing experience! Truly inspiring and well-organized.
                </p>
                <span>- Alex P.</span>
              </div>
              <div className="testimonial">
                <p>
                  The team goes above and beyond to make each event special.
                  Highly recommend!
                </p>
                <span>- Maria S.</span>
              </div>
              <div className="testimonial">
                <p>
                  Fantastic events that bring people together. Love being a part
                  of it!
                </p>
                <span>- John D.</span>
              </div>
            </div>
            <div className="testimonials-grid">
              <div className="testimonial">
                <p>
                  An amazing experience! Truly inspiring and well-organized.
                </p>
                <span>- Alex P.</span>
              </div>
              <div className="testimonial">
                <p>
                  The team goes above and beyond to make each event special.
                  Highly recommend!
                </p>
                <span>- Maria S.</span>
              </div>
              <div className="testimonial">
                <p>
                  Fantastic events that bring people together. Love being a part
                  of it!
                </p>
                <span>- John D.</span>
              </div>
            </div>
          </section>

          <section className="contact-section">
            <p>Want to get involved or have any questions? Reach out to us!</p>
            <Link to="/contactus"><button className="contact-button">Get in Touch</button></Link>
          </section>

          {selectedImage && (
            <div className="modal" onClick={closeModal}>
              <div className="modal-content">
                <span className="modal-close" onClick={closeModal}>
                  &times;
                </span>
                <img src={selectedImage.src} alt={selectedImage.title} />
                <p className="modal-title">{selectedImage.title}</p>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};
