import React from "react";
import { Footer } from "../../container/footerSection";
import { AboutContactUs } from "./aboutContactUs";
import { AboutUsTopSection } from "./aboutUsTopSection";
import { EthicsFirst } from "./ethicsFirst";
// import { HomeTimeAndEfforts } from "./homeTimeAndEfforts";
import { OurUSP } from "./ourUSP";
import { TechnologyIntegratingSection } from "./technologyIntegratingSection";
// import { WorkCulture } from "./workCulture";

export const AboutUs = () => {
  return (
    <>
      <AboutUsTopSection />
      <TechnologyIntegratingSection />
      <OurUSP />
       {/* <WorkCulture />  */}
      {/* <HomeTimeAndEfforts /> */}
      <EthicsFirst />
      <AboutContactUs />
      {/* <ScrollToAnchor/> */}
      <Footer />
    </>
  );
};
