import React, { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import { File } from "@babel/types";
import crossImage from "../../../assets/images/icons8-cancel.svg";

import {
  isCountryCodeValid,
  isNameValid,
  isEmailValid,
  isPhoneNumberValid,
  isMessageValid,
  API2,
} from "../../../utils";
import { API, request } from "../../../utils";
import { useForm } from "../../../hooks";
import { Form_Error_Message } from "../../../utils";
import { Button } from "../../../components/button";
import "../CareersForm.css"


export const CareersFormUpload = () => {
  const [radioValue, setRadioValue] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [formEnteredData, setFormEnteredData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countryCodeValue, setCountryCodeValue] = useState("91");

  const {
    value: fullNameValue,
    valueChangeHandler: fullNameChangeHandler,
    valueBlurHandler: fullNameBlurHandler,
    hasError: fullNameHasError,
    valueIsValid: fullNameIsValid,
    resetValue: fullNameResetValue,

  } = useForm(isNameValid);

  const {
    // value: countryCodeValue,
    valueChangeHandler: countryCodeChangeHandler,
    valueBlurHandler: countryCodeBlurHandler,
    hasError: countryCodeHasError,
    valueIsValid: countryCodeIsValid,
    resetValue: countryCodeResetValue,
  } = useForm(isCountryCodeValid);

  const {
    value: emailValue,
    valueChangeHandler: emailChangeHandler,
    valueBlurHandler: emailBlurHandler,
    hasError: emailHasError,
    valueIsValid: emailIsValid,
    resetValue: emailResetValue,
  } = useForm(isEmailValid);

  const {
    value: phoneNumberValue,
    valueChangeHandler: phoneNumberChangeHandler,
    valueBlurHandler: phoneNumberBlurHandler,
    hasError: phoneNumberHasError,
    valueIsValid: phoneNumberIsValid,
    resetValue: phoneNumberResetValue,
  } = useForm(isPhoneNumberValid);

  const {
    value: messageValue,
    valueChangeHandler: messageChangeHandler,
    valueBlurHandler: messageBlurHandler,
    hasError: messageHasError,
    valueIsValid: messageIsValid,
    resetValue: messageResetValue,
  } = useForm(isMessageValid);

  let formIsValid = false;

  if (
    fullNameIsValid &&
    radioValue &&
    emailIsValid &&
    phoneNumberIsValid &&

    messageIsValid
  ) {
    formIsValid = true;
  }

  const handleRadioValueChange = (event) => {
    setRadioValue(event.target.value);
    setShowWarning(false);
  };

  const countryCodeValueChange = (newCountryCodeValue) => {
    // console.log(" ",event);
    setCountryCodeValue(newCountryCodeValue);
   
  };
  // const resumeHandler = (event) => {
  //   const selectedResume = event.target.files[0];
  //   setResume(selectedResume);
   
  // };
  // const clearFile = () => {
  //   setResume('');
  //   // Optionally, you can also clear the input value (if needed)
  //   const fileInput = document.getElementById('fileInput');
  //   if (fileInput) {
  //     fileInput.value = null;
  //   }
  // };


  // const notify = (msg) => toast(msg);

  const submitHandler = (event) => {
    event.preventDefault();

    if (!radioValue) {
      setShowWarning(true);
    } else {
      setRadioValue(event.target.value);
    }

    console.log("H", countryCodeValue);
    setFormEnteredData({
      full_name: fullNameValue,
      email: emailValue,
      phone_number: phoneNumberValue,
      about_us: radioValue,
      message: messageValue,
      country_code : countryCodeValue,
    });
    console.log("Testing",formEnteredData)

    fullNameResetValue();
    countryCodeResetValue();
    emailResetValue();
    phoneNumberResetValue();
    messageResetValue();
    setRadioValue();
    
  };

  useEffect(() => {
    if (formEnteredData) {
      setIsLoading(true);
      requestData(formEnteredData)
        .then(() => {
          setIsLoading(false);
          return toast("Details Submitted Successfully! ThankYou");
        })
        .catch(() => {
          setIsLoading(false);
          toast("Something went Wrong. Please try again later");
        });
    }
    setFormEnteredData("");
  }, [formEnteredData]);

  return (
    <div className="container text-center hire-form-container">
      <div className="row align-items-start">
        <div className="col">
          <ToastContainer />
          <div className="hire-developer-card">
            <div className="hire-developer-right-title">
              Get in touCH with us
              <div className="hire-developer-divider">
                <span className="hire-developer-divider-separator"></span>
              </div>
            </div>
            <form onSubmit={submitHandler}>
              <div className="hire-developer-form-container">
                <div className="col">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control hire-developer-form-control"
                      placeholder="Your full name"
                      aria-label="Yourfullname"
                      onChange={fullNameChangeHandler}
                      onBlur={fullNameBlurHandler}
                      value={fullNameValue}
                    />
                    <div className="hire-developer-mobile-error">
                      {fullNameHasError && !formIsValid && (
                        <span>{Form_Error_Message.invalidFullName}</span>
                      )}
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <PhoneInput
                          country={"in"}
                          className="ph-in mt-input"
                          placeholder="Enter country code"
                          enableSearch={true}
                          onChange={countryCodeValueChange}
                      
                          value={countryCodeValue}
                          
                    />
                    <div className="hire-developer-error">
                      {countryCodeHasError && !formIsValid && (
                        <span>{Form_Error_Message.invalidCountryCode}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="input-group mb-3">
                    <input
                      type="input"
                      className="form-control hire-developer-form-control"
                      placeholder="Your Personal Email"
                      aria-label="YourPersonalEmail"
                      onChange={emailChangeHandler}
                      onBlur={emailBlurHandler}
                      value={emailValue}
                    />
                    <div className="hire-developer-error hire-developer-email-error">
                      {emailHasError && !formIsValid && (
                        <span>{Form_Error_Message.invalidBusinessEmail}</span>
                      )}
                    </div>
                  </div>
                  <div className="input-group mb-3">
                     <input
                      type="text"
                      className="form-control hire-developer-form-control"
                      placeholder="Your phone number"
                      aria-label="Yourphonenumber"
                      onChange={phoneNumberChangeHandler}
                      onBlur={phoneNumberBlurHandler}
                      value={phoneNumberValue}
                    /> 
                    <div className="hire-developer-error hire-developer-email-error">
                      {phoneNumberHasError && !formIsValid && (
                        <span>{Form_Error_Message.invalidPhoneNumber}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row hire-developer-radio">
                <label className="form-check-label hire-form-check">
                  How did you hear about us?*
                </label>
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="google"
                      value="google"
                      checked={radioValue === "google"}
                      onChange={handleRadioValueChange}
                    />
                    <label className="form-check-label" htmlFor="google">
                      Google
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="linkedin"
                      value="linkedin"
                      checked={radioValue === "linkedin"}
                      onChange={handleRadioValueChange}
                    />
                    <label className="form-check-label" htmlFor="linkedin">
                      LinkedIn
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="facebook"
                      value="facebook"
                      checked={radioValue === "facebook"}
                      onChange={handleRadioValueChange}
                    />
                    <label className="form-check-label" htmlFor="facebook">
                      Facebook
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="instagram"
                      value="instagram"
                      checked={radioValue === "instagram"}
                      onChange={handleRadioValueChange}
                    />
                    <label className="form-check-label" htmlFor="instagram">
                      Instagram
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="friend_suggested"
                      value="friend_suggested"
                      checked={radioValue === "friend_suggested"}
                      onChange={handleRadioValueChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="friend_suggested"
                    >
                      Friend Suggested
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="Others"
                      value="Others"
                      checked={radioValue === "Others"}
                      onChange={handleRadioValueChange}
                    />
                    <label className="form-check-label" htmlFor="Others">
                      Others
                    </label>
                  </div>
                </div>
                {showWarning && !formIsValid && (
                        <span>{Form_Error_Message}</span>
                )}
              </div>
{/* 
              <div className="hire-developer-textarea">
                <div className="input-group">
                  
                  <input type='file'
                  accept="application/pdf,applicationmsword,application/vnd.openxmlformats-officedocument.wordprocessing.document"
                    className="form-control"
                    aria-label="With textarea"
                    placeholder="Browse File"
                    id="fileInput"
                  

                    // onChange={resumeHandler}
                    // onBlur={messageBlurHandler}
                    // value={resume}

                  />
                  {console.log('RRRRRRRRRRRRRRRR', resume)}
                  {resume && (
                 <img
                 src={crossImage}
              alt="Close"
            className="close-icon"
            onClick={clearFile}
          />
        )}
                </div>
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label hire-developer-textarea-label"
                >
                           Please upload file in .pdf,.doc,.docx format only
                </label>
                <div className="hire-developer-textarea-error">
                  {messageHasError && !formIsValid && (
                    <span>{Form_Error_Message.documentNotUploaded}</span>
                  )}
                </div>
              </div> */}

              <div className="hire-developer-textarea">
                <div className="input-group">
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    placeholder="Message"
                    onChange={messageChangeHandler}
                    onBlur={messageBlurHandler}
                    value={messageValue}
                  />
                </div>
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label hire-developer-textarea-label"
                >
                  {/* The messages should not exceed 300 words */}
                </label>
                <div className="hire-developer-textarea-error">
                  {messageHasError && !formIsValid && (
                    <span>{Form_Error_Message.emptyMessage}</span>
                  )}
                </div>
              </div>



              <div className="hire-devs-button">
                <Button
                  className="hire-developers-button"
                  type={"submit"}
                  disabled={!formIsValid}
                >
                  {isLoading ? (
                    <span className="hire-developer-card-spinner"></span>
                  ) : (
                    <span className="talk-to-a-friend">Submit</span>
                  )}

                  {!isLoading && (
                    <div className="hire-devs-arrow-icon">
                      <FaLongArrowAltRight />
                    </div>
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const requestData = (formEnteredData) => {
  const response = request({
    method: "post",
    url: API.contactAPI.contactServices,
    userData: formEnteredData,
  });

  return response;
};
