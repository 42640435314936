// ScrollContext.js
import React, { createContext, useContext } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const ScrollContext = createContext();

export function ScrollProvider({ children}) {
  const navigate = useNavigate()
  const location = useLocation();
    
     const scrollToSection = (sectionId) => {
    
    if (location.pathname === "/" || location.pathname === "/aboutus") {

      if(isMobile){
        window.scrollTo({
          top: 7900,
          left: 10,
          behavior: 'smooth',
        });
        return;
      }

      if(isMobile && sectionId === "ourservice"){
        window.scrollTo({
          top: 1100,
          left: 10,
          behavior: 'smooth',
        });
        return;
      }
    
      window.scrollTo({
        top: 3800,
        left: 100,
        behavior: 'smooth',
      });
    }

    if (sectionId === "ourservice") { 
      navigate("/")
        window.scrollTo({
          top: 600,
          left: 0,
          behavior: 'smooth',
        });
      }
  }

  return (
    <ScrollContext.Provider value={{scrollToSection}}>
      {children}
    </ScrollContext.Provider>
  );
}

export function useScroll() {
  return useContext(ScrollContext);
}
