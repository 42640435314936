import React from "react";

import { useParams } from "react-router-dom";
import hireDevelopersContent from "../../../../constants/constants.json";
import "./HireDevelopersContent.css";

export const HireDevelopersContent = () => {
  const { contentId } = useParams();

  const content = hireDevelopersContent[0].find((el) => el.id === contentId);

  return (
    <>
      <div className="hire-developer-title-left">{content?.title}</div>
      <div className="hire-developer-left-body">{content?.subTitle}</div>
      <div className="hire-developer-left-subbody">{content?.body}</div>
    </>
  );
};
