import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";

import WorldLine from "../../../assets/images/world_line.png";
import { Button } from "../../../components/button";
import "./HomeTop.css";
import { useNavigate } from "react-router-dom";

export const HomeTop = () => {
  const navigate = useNavigate();

  const navigateHandler = () => {
    navigate("/staffAugmentation");
  };
  return (
    <section className="home-section ">
      <div className="home-container ">
        <div className="home-row widget-container-image">
          <div className="home-column">
            <div className="home-widget-container">
              <div className="image-container">
                <img className="Home-top-banner-image" alt="" src={WorldLine} />
              </div>
            </div>
          </div>
          <div className="home-column home-home home-content-small home-side-container-animate">
            <section className="">
              <div className="home-widget-wrap">
                <div className="home-home home-home-top">
                  <div className="home-widget-container">
                    <h1 className="home-heading-title">
                    Accelerate your buisness with our premium IT solutions and AI-driven services designed to optimize performance and unlock new opportunities.
                    </h1>
                  </div>
                  {/* <Button
                    className="hire-developer-button"
                    onClick={navigateHandler}
                  >
                    Start Hiring
                    <div className="arrow-icon">
                      <FaLongArrowAltRight />
                    </div>
                  </Button> */}
                  
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};
