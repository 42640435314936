import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./HomeTestimonials.css";
import {Footer} from "../../../container/footerSection";
export const HomeTestimonials = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <>
    <div className="testimonial-main-header" data-aos="fade-up">
      <h1 className="testimonial-heading">Hear From The People Who Trust Us</h1>
      <hr className="horzontal-line"></hr>
      <text className="testimonial-word">Testimonials</text>
      <div className="video-responsive">
        <iframe
          loading="lazy"
          width="560"
          height="290"
          src="https://www.youtube.com/embed/pozW3FX_XBU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
        ></iframe>
        <iframe
          loading="lazy"
          width="560"
          height="290"
          src="https://www.youtube.com/embed/pozW3FX_XBU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
        ></iframe>
      </div>
    </div>
    <Footer/>
    </>
  );
};
