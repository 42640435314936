import React from 'react'
import "./AiServices.css"

const aiAccordion = () => {
  return (
    <div>
         <div class="accordion ai-accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      AI-Powered Web Development
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" >
      <div class="accordion-body">Transform your ideas into stunning websites with TaskCraft's AI-driven web development services. Our advanced algorithms ensure faster development, seamless designs, and optimized performance tailored to your business needs. Experience cutting-edge technology at your fingertips..</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      Custom Mobile App Development
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Build intuitive and user-friendly mobile applications with TaskCraft. From concept to launch, our AI-enhanced tools accelerate development, ensuring feature-rich apps for Android and iOS platforms, perfect for engaging your audience and scaling your business.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      Automated UI/UX Design
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">At TaskCraft, we use AI to design beautiful, functional, and user-centric interfaces. Whether it's a web or mobile application, our automated processes ensure faster prototyping, seamless user experiences, and innovative designs that captivate users.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree">
      Scalable Solutions for Startups
      </button>
    </h2>
    <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">TaskCraft provides cost-effective, scalable solutions for startups. Our AI tools empower you to build MVPs quickly, validate your ideas, and scale effortlessly as your business grows—all while staying within budget and meeting tight deadlines.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseThree">
      AI-Enhanced Code Optimization
      </button>
    </h2>
    <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Our AI-driven code optimization tools improve your app's performance, security, and maintainability. TaskCraft ensures that your applications are efficient, lightweight, and ready for high traffic, giving you an edge in the competitive digital space.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseThree">
      Seamless Integrations and Maintenance
      </button>
    </h2>
    <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">TaskCraft specializes in integrating third-party services and maintaining your web or mobile applications. With AI monitoring and predictive maintenance, we ensure your applications run smoothly, minimizing downtime and enhancing user satisfaction.</div>
    </div>
  </div>
</div>
    </div>
  )
}

export default aiAccordion
