import React from "react";

import { HireDevelopersContent } from "./hireDevelopersContent";
import { HireDevelopersForm } from "./hireDevelopersForm";

import "./HireDevelopersTopSection.css";

export const HireDevelopersTopSection = () => {
  return (
    <>
      <section >
        <div className="hire-developers-main-section">
          <div className="container text-center">
            <div className="row align-items-start">
              <div className="col">
                <HireDevelopersContent />
              </div>
              <div className="col">
                <HireDevelopersForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
