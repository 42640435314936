import React from "react";

import MessageIcon from "../../../assets/images/messageicon.png";
import FingerTipIcon from "../../../assets/images/fingertipicon.png";
import settings from "../../../assets/setting.png";
import "./OurUSP.css";

export const OurUSP = () => {
  return (
    <section className="usp-main-section">
      <div className="container text-center">
        <div className="row align-items-start">
          <div className="usp-title">
            <h1>TaskCraft USP</h1>
          </div>
          <div className="usp-subtitle">
          At  <b>TaskCraft</b>, we take pride in seamlessly integrating creativity, innovation,
          and industry-leading methodologies to deliver transformative solutions.
          By leveraging a strategic and tailored approach, we craft outcomes that consistently surpass expectations. 
          Our unwavering commitment to excellence drives measurable results,
           empowering our clients to achieve sustainable growth, enhanced efficiency, 
          and a competitive edge in an ever-evolving digital landscape.
          </div>
        </div>
        <div className="row align-items-start">
          <div className="col">
            <div className="usp-card" data-aos="zoom-in">
              <div className="col">
                <div className="usp-card-title">Seamless Communication</div>
                <div className="usp-card-content">
                  We have a 24/7 active communication channel to ensure that
                  every query is responded on time. We aim to keep our
                  communications as seamless as possible.
                </div>
              </div>
              <div className="col">
                <div className="usp-card-image">
                  <img src={MessageIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="usp-card" data-aos="zoom-in">
              <div className="col">
                <div className="usp-card-title">Technology Expertise</div>
                <div className="usp-card-content">
                  To cater to complex problems and requirements and provide
                  out-of-the-box solutions, we follow continuous technology
                  adaptation and innovation.
                </div>
              </div>
              <div className="col">
                <div className="usp-card-image">
                  <img src={FingerTipIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-start">
          <div className="usp-card usp-card-third" data-aos="zoom-in">
            <div className="col">
              <div className="usp-card-title">Prompt Execution</div>
              <div className="usp-card-content">
                We are committed to adhering to stringent measures to ensure the
                seamless execution of our services within the stipulated time
                frame. Our team prioritizes efficiency and precision, striving
                to deliver desired outcomes swiftly while maintaining the
                highest standards of quality. By leveraging advanced tools and
                methodologies, we ensure that every project is handled with
                care, meeting deadlines without compromising on excellence.
              </div>
            </div>
            <div className="col">
              <div className="usp-card-image">
                <img src={settings} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
