// import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import { API, request } from "./utils";
import { Home } from "./screens/home";
import { routes } from "./routes/routes";
import { HireDevelopers } from "./screens";
import { Header } from "./container/header";
import { ContactUs } from "./screens";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { AboutUs } from "./screens";
import { ServicePage } from "./screens/home/servicepage/ServicePage";
import { CareersForm } from "./screens/careers/CareersForm";
import { ScrollProvider } from './context/ScrollContext';
import { IndustriesProvider } from "./context/IndustriesContext";
import { RadioProvider } from "./context/RadioContext";
import ScrollToTop from "react-scroll-to-top";
import AiServices from "./screens/aiServices/AiServices";
import {Events} from "./screens/events/Events"
import Team from "./screens/team/Team";
import { TermsandCondition } from "./terms";
import {HomeTestimonials} from './screens/home/homeTestimonials/HomeTestimonials'

import Blog from "./screens/Blogs/Blog";
import AIML from "./screens/solutionPages/AIML";
import Ecommerce from "./screens/solutionPages/Ecommerce";
import NoCodeLowCode from "./screens/solutionPages/NoCodeLowCode";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop smooth color="#f28c04" />
      <RadioProvider>
        <ScrollProvider>
          <IndustriesProvider>
            <Header />

            <Routes>
              <Route path={routes.general.home} element={<Home />} />
              <Route
                path={routes.general.hireDevelopers}
                element={
                  <>
                    <HireDevelopers />
                  </>
                }
              />
              <Route
                path={routes.general.contactUs}
                element={
                  <>
                    <ContactUs />
                  </>
                }
              />
              <Route
                path={routes.general.careers}
                element={
                  <>
                    <CareersForm />
                  </>
                }
              />
              <Route path={routes.general.aboutUs} element={<AboutUs />} />
              {/* <Route path={routes.general.ai} element={<AiServices/>}/>  */}
              <Route
          path={routes.general.servicepage}
          element={
            <>
              <ServicePage/>
            </>
          }
        />      
              <Route path={routes.general.events} element={<Events/>}/>
              <Route path={routes.general.Team} element={<Team/>} />
              <Route path={routes.general.ai} element={<AiServices/>}/>
              <Route path={routes.general.Blog} element={<Blog/>}/>
              <Route path="/TermsandCondtion" element={<TermsandCondition/>} />
              <Route path={routes.general.aiml} element={<AIML/>} />
              <Route path={routes.general.ecommerce} element={<Ecommerce/>} />
              <Route path={routes.general.nocodelowcode} element={<NoCodeLowCode/>} />
              <Route path={'HomeTestimonials'} element={<HomeTestimonials/>} />
            </Routes>
          </IndustriesProvider>
        </ScrollProvider>
      </RadioProvider>
    </BrowserRouter>
  );
}

export default App;