import React from "react";

import Arrow from "../../../assets/images/arrow.png";
import Eye from "../../../assets/images/eye.png";
import Diamond from "../../../assets/images/diamond.png";
import "./EthicsFirst.css";

export const EthicsFirst = () => {
  return (
    <section className="cover-main-section">
      <div className="container text-center">
        <div className="row align-items-start">
          <div className="cover-title">
            <h1>Ethics First</h1>
          </div>
          <div className="cover-subtitle">
            Placing integrity and ethical principles at the pinnacle of our business ethos.
          </div>
        </div>
        <div className="row align-items-start">
          <div className="col">
            <div className="cover-card" data-aos="zoom-in">
              <div className="col">
                <div className="cover-card-title">Mission</div>
                <div className="cover-card-content">
                Leveraging the transformative potential of technology to deliver dynamic, efficient, 
                and innovative solutions that drive business success
                </div>
              </div>
              <div className="col">
                <div className="cover-card-image">
                  <img src={Arrow} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="cover-card" data-aos="zoom-in">
              <div className="col">
                <div className="cover-card-title">Vision</div>
                <div className="cover-card-content">
                Empowering ambitions with innovative, inspired,
                and visionary software solutions that redefine possibilities
                </div>
              </div>
              <div className="col">
                <div className="cover-card-image">
                  <img src={Eye} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-start">
          <div className="cover-card cover-card-third" data-aos="zoom-in">
            <div className="col">
              <div className="cover-card-title">Values</div>
              <div className="cover-card-content-main-div">
                <div className="cover-card-content">
                  <b>Integrity as Core Value, </b>
                  <b>Honesty and Transparency, </b>
                  <b>Confidentiality and Data Protection, </b>
                  <b>Continuous Improvement</b>
                </div>
                <div className="col">
                  <div className="cover-card-image">
                    <img src={Diamond} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
