export const API = {
  contactAPI: {
    contactUs: "/contacts",
    contactServices: "/contactservices",
  },
};

// export const API2 = {
//   careersAPI: {
//     careers: "/hires",
    
//   },
// };

