export const Form_Error_Message = {
  invalidFullName: "Enter a valid full name.",
  invalidPhoneNumber: "Enter a valid phone number.",
  invalidBusinessEmail: "Enter a valid email",
  invalidCountryCode: "Enter a valid country code.",
  emptyMessage: "Message cannot be empty.",
  radioNotSelected: "Please select an option.",
  // documentNotUploaded: "Please upload your resume",
};
export const success = 'success';
export const error = 'error';