import React from "react";

import { useParams } from "react-router-dom";
import careersFormUploadContent from "../../../../constants/constants.json"
import "./CareersFormUploadContent.css";
export const CareersFormUploadContent = () => {
  const { contentId } = useParams();

  const content = careersFormUploadContent[0].find((el) => el.id === "careers");
  console.log(contentId);
  console.log(content);

  return (
    <>
      <div className="hire-developer-title-left">{content?.title}</div>
      <div className="hire-developer-left-body">{content?.subTitle}</div>
      <div className="hire-developer-left-subbody">{content?.body} 
</div>
    </>
  );
};
